import { ChangeEvent, FC, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import Header from '../components/Header'
import { useMoralis } from '../hooks/moralis'
import { toast } from 'react-toastify'
import Tooltip from '../components/Tooltip'
import Toast from '../components/toast'
import { localGet, localSet } from '../utils/localStorage'
import { mock } from 'mockjs'
import avatar from '../images/common/avater.png'
import createDAO from '../images/user/createDAO.png'
import createNFT from '../images/user/createNFT.png'
import linkWallet from '../images/user/linkWallet.png'
import transaction from '../images/user/transaction.png'
import TransactionsChart from '../components/TransactionsChart'
import data from '../images/user/data.png'
import activeData from '../images/user/activeData.png'
import account from '../images/user/account.png'
import activeAccount from '../images/user/activeAccount.png'
import history from '../images/user/history.png'
import activeHistory from '../images/user/activeHistory.png'
import signout from '../images/user/signout.png'
import activeSignout from '../images/user/activeSignout.png'
import noConnectWallet from '../images/user/noConnectWallet.png'
import trend from '../images/user/trend.png'
import eth from '../images/common/eth.svg'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CleanValueIcon } from '../components/CleanValueIcon'
import { ErrorMessage } from '../components/ErrorMessage'
import Pagination from '../components/Pagination'
import SpinLoading from '../components/SpinLoading'
import dayjs from 'dayjs'

type UserInfoFormType = {
  firstName: string
  lastName: string
  email: string
  about: string
}

const User: FC = () => {
  const [xData, setXData] = useState<any>()
  const [seriesData, setSeriesData] = useState<any>()
  const [market, setMarket] = useState<any>()
  const [totalTransaction, setTotalTransaction] = useState<any>()
  const [totalNFT, setTotalNFT] = useState<any>()
  const [currentTab, setCurrentTab] = useState(0)
  const [uploadPhoto, setUploadPhoto] = useState<string | null>(null)
  const [userBalance, setUserBalance] = useState()
  const [userAddress, setUserAddress] = useState()
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    about: '',
  })
  const [listLoading, setListLoading] = useState(true)
  const transactionHistoryList = new Array(10).fill('').map(() =>
    mock({
      name: '@name()',
      'comment|1': ['A dectease in income', 'A increment in income'],
      deadline: '@datetime(HH:mm:ss A 2022-MM-dd)',
      transaction: 'BUY',
      amount: '@float(-10000,10000,2,2)',
    }),
  )
  // Transaction history List random Color
  const randomColor = [
    { textColor: '#F55F44', bgColor: '#FFDED8FF' },
    { textColor: '#338FBF', bgColor: '#BFE9FFFF' },
    { textColor: '#BC6E82', bgColor: '#FED0DCFF' },
    { textColor: '#74A895', bgColor: '#B9EAD8FF' },
    { textColor: '#854A84', bgColor: '#E6B4E5FF' },
  ]
  const onPageChange = async (page: number) => {
    if (
      typeof window !== 'undefined' &&
      768 <= window.document.body.offsetWidth
    ) {
      window?.scroll({ top: 320, behavior: 'smooth' })
    }
    setListLoading(true)
    const curPage = await new Promise(resolve =>
      setTimeout(resolve, 1200, page),
    )
    setListLoading(false)
  }

  const {
    connectWallet,
    logout,
    uploadFiles,
    updateUser,
    updateAvatar,
    getNFTsByContract,
    Moralis,
    getGasFromEthTransactions,
    getUserBalances,
    getSoldData,
    getTotalTransactions,
    getTotalMintNFT,
  } = useMoralis()
  const handleConnectWallet: any = () => {
    connectWallet?.() ||
      toast.error(
        "Something seems to be going wrong, I can't connect to the wallet",
      )
  }
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UserInfoFormType>({
    defaultValues: userInfo,
  })
  const handleUploadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]
    file && setUploadPhoto(window.URL.createObjectURL(file) ?? null)
    updateAvatar?.({ files: e.target?.files })
  }
  const onSubmit: SubmitHandler<UserInfoFormType> = data => {
    updateUser?.(data)
  }
  // Top Action Menu
  const actionMenu = [
    {
      title: 'Create a DAO',
      icon: createDAO,
      action: () => navigate('/createDao'),
    },
    {
      title: 'My NFT',
      icon: createNFT,
      action: () => navigate('/NFTCreatorsDetails'),
    },
    {
      title: 'Link Wallet',
      icon: linkWallet,
      action: handleConnectWallet,
    },
    /*     {
      title: 'Transaction',
      icon: transaction,
      action: () => navigate('/send'),
    }, */
  ]

  const changeCurrentTab = (tab: number) => {
    if (typeof window !== 'undefined') {
      setCurrentTab(tab)
      if (0 <= tab && window.document.body.offsetWidth <= 768) {
        window?.scroll({ top: 0 })
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  }

  // bottom Tab List
  const tabList = [
    {
      icon: data,
      activityIcon: activeData,
      title: 'Data market',
      action: () => changeCurrentTab(0),
    },
    {
      icon: account,
      activityIcon: activeAccount,
      title: 'Account settings',
      action: () => changeCurrentTab(1),
    },
    {
      icon: history,
      activityIcon: activeHistory,
      title: 'Transaction history',
      action: () => changeCurrentTab(2),
    },
    {
      icon: signout,
      activityIcon: activeSignout,
      title: 'Log out',
      action: logout,
    },
  ]
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // initialization
      if (window.document.body.offsetWidth <= 768) {
        setCurrentTab(-1)
      } else {
        setCurrentTab(0)
      }
      // Responsive
      window.onresize = () => {
        if (window.document.body.offsetWidth <= 768) {
          changeCurrentTab(-1)
        } else {
          changeCurrentTab(0)
        }
      }
      const user = Moralis.User.current()
      console.log(user)
      const handleDataMarket = () => {
        // TODO: some data of the data market
        getGasFromEthTransactions?.().then(result => {
          const x = result?.map(item =>
            dayjs(item.createAt).locale('en').format('MMM D YYYY'),
          )
          const receipt_gas_used = result?.map(item => item.receipt_gas_used)
          const gas = result?.map(item => item.gas)
          const receipt_cumulative_gas_used = result?.map(
            item => item.receipt_cumulative_gas_used,
          )
          setXData(x)
          setSeriesData({ receipt_gas_used, gas, receipt_cumulative_gas_used })
        })
        getSoldData?.().then(price => {
          setMarket(price?.slice(0, 6))
        })
        getTotalTransactions?.().then(result => {
          setTotalTransaction(result)
        })
        getTotalMintNFT?.().then(result => {
          setTotalNFT(result)
        })
      }
      handleDataMarket()
      const initializeUserInfo = () => {
        if (user) {
          setUploadPhoto(user?.get('avatar')?._url)
          const { firstName, lastName, email } = user.attributes
          setValue('firstName', firstName)
          setValue('lastName', lastName)
          setValue('email', email)
          setValue('about', user?.get('about') || '')
          setUserInfo(user?.attributes)
          setUserAddress(
            user?.get('ethAddress') ??
              window?.ethereum?.selectedAddress ??
              '0.0000000000000',
          )
          getUserBalances?.().then((result: any) => {
            setUserBalance(result)
          })
        } else {
          navigate('/')
        }
      }
      initializeUserInfo()
    }
  }, [])

  return (
    <div className="bg-[#F3F7FA] pb-20 overflow-hidden sm:pb-0">
      <Header />
      <Toast />
      <div className="pt-20 sm:pt-[57px] h-[377px] font-regularText sm:h-[347px] bg-[linear-gradient(135deg,#92CEFF33_0%,#F875751A_117%)] justify-between">
        <div className="flex justify-between mx-auto max-w-7xl md:px-16 sm:px-4 sm:w-full sm:flex-col">
          {/* Top Window PC Action Menu */}
          <div className="mt-[38px] flex sm:w-full">
            <img
              src={uploadPhoto ?? avatar}
              alt="avatar"
              className="h-[135px] w-[135px] border-2 border-solid border-white rounded-full object-cover sm:w-[62px] sm:h-[62px]"
            />
            <div className="mt-[10px] ml-[21px] sm:mt-0 sm:ml-4 sm:flex sm:flex-col sm:justify-center">
              <div className="text-[29px] leading-[34px] font-bold font-boldText sm:text-[22px] sm:leading-[26px]">
                {userInfo?.username || 'Not Logged In'}
              </div>
              <div className="mt-6 text-[15px] leading-[18px] sm:mt-1">
                {userAddress}
              </div>
              <div className="mt-3 text-[15px] leading-[18px] sm:mt-1">
                {/* $AUSD */}
              </div>
              <div className="flex mt-9 sm:hidden">
                {actionMenu.map((x, i) => (
                  <div
                    key={i}
                    className="bg-white h-14 p-[10px] mr-4 last:mr-0 rounded-[28px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] flex items-center overflow-hidden transition-all duration-300 max-w-[56px] hover:max-w-[163px] cursor-pointer group"
                    onClick={x.action}
                  >
                    <img src={x.icon} alt={x.title} className="w-9 h-9" />
                    <div className="ml-2 whitespace-nowrap text-[14px] leading-4 text-white group-hover:text-[#828282] transition-all">
                      {x.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Top Mobile Phone Action Menu */}
          <div className="justify-between hidden sm:flex mt-9">
            {actionMenu.map((x, i) => (
              <div key={i} className="flex flex-col items-center">
                <div
                  key={i}
                  className="bg-white p-[18px] rounded-3xl shadow-[0_16px_24px_1px_#0000000a]"
                  onClick={x.action}
                >
                  <img src={x.icon} alt={x.title} className="w-9 h-9" />
                </div>
                <div className="mt-[13px] text-[#828282] text-xs">
                  {x.title}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-[68px] sm:hidden">
            <div className="text-[15px] leading-[18px]">PRICE</div>
            <div className="text-[#1E94FC] mt-[18px] flex items-end">
              <div>ETH</div>
              <div className="text-[38px] leading-9 font-numberText ml-2">
                {userBalance?.substring(0, userBalance?.length - 10) || '0.00'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mx-auto mt-8 max-w-7xl md:px-16 sm:px-0 sm:flex-col sm:mt-0">
        {/* Mobile Data Market */}
        <div
          className="hidden sm:block bg-white px-4 pt-[52px] pb-[42px]"
          key={0}
        >
          <div className="text-[22px] leading-[26px] font-boldText font-bold">
            Data market
          </div>
          {userBalance ? (
            <div className="mt-3 shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded-2xl pt-[34px] px-4">
              <div className="flex items-center justify-between">
                <div>
                  <div className="text-[28px] leading-9 font-numberText">
                    $1,020.46
                  </div>
                  <div className="mt-2 text-[#828282] text-[12px] leading-4 font-numberText">
                    {new Date().toLocaleString()}
                  </div>
                </div>
                {/* <div className="flex items-center text-[#D1D1D1]">
                  <div className="text-sm font-numberText">24H</div>
                  <svg
                    className="w-3 h-3 ml-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div> */}
              </div>
              <div className="h-[200px] mt-8 w-full">
                <img
                  src={trend}
                  alt="trend"
                  className="object-contain w-full h-full"
                />
              </div>
            </div>
          ) : (
            <div className="mt-12 h-[380px] flex flex-col items-center">
              <img
                src={noConnectWallet}
                alt="noConnectWallet"
                className="w-[243px] object-cover"
              />
              <div className="text-[#828282] mt-8">
                No data yet, please connect your wallet first
              </div>
              <button
                className="w-[119px] h-12 rounded-[60px] bg-[linear-gradient(270deg,#1E94FC_0%,#AED8FF_100%)] text-white text-sm mt-8 hover:shadow-[0_6px_18px_4px_#c0e2ff]"
                onClick={handleConnectWallet}
              >
                Link wallet
              </button>
            </div>
          )}
        </div>
        {/* PC and Mobile Tab change List */}
        <div className="w-[261px] h-[407px] bg-white rounded-2xl py-8 mr-8 sm:mr-0 sm:mt-4 sm:h-auto sm:w-auto sm:px-4 sm:py-0 sm:rounded-none">
          {tabList.map((x, i) => (
            <div
              key={i}
              className={`relative items-center h-6 pl-8 mt-8 cursor-pointer select-none first:mt-0 before:bg-[#1E94FC] before:absolute before:left-0 before:h-full before:rounded-sm hover:text-[#1E94FC] sm:hover:text-[#333] sm:before:hidden sm:h-[98px] sm:p-0 sm:border-b sm:border-[#F2F2F2] sm:last:h-[59px] sm:last:rounded-2xl sm:last:bg-[#F8F8F8] sm:last:mt-[42px] sm:last:mb-[52px] sm:last:justify-center group sm:flex sm:items-center sm:justify-between ${
                currentTab !== i
                  ? 'text-[#333]'
                  : 'text-[#1E94FC] sm:text-[#333]'
              } ${currentTab !== i ? 'before:w-0' : 'before:w-1'}`}
              onClick={() => x.action?.()}
            >
              <div className="flex items-center">
                <img
                  src={x.icon}
                  alt={x.title}
                  className={`w-6 h-6 group-hover:hidden sm:group-hover:flex sm:group-last:group-hover:hidden sm:group-last:hidden ${
                    currentTab !== i ? 'flex' : 'hidden sm:flex'
                  }`}
                />
                <img
                  src={x.activityIcon}
                  alt={x.title}
                  className={`w-6 h-6 group-hover:flex sm:group-hover:hidden sm:group-last:hidden ${
                    currentTab !== i ? 'hidden' : 'flex sm:hidden'
                  }`}
                />
                <div className="ml-[6px] w-full sm:text-[18px] sm:leading-[22px] sm:group-last:w-auto sm:group-last:sm:text-[#828282] sm:w-auto">
                  {x.title}
                </div>
              </div>
              <svg
                className="w-6 h-6 hidden sm:block text-[#BDBDBD] group-last:hidden"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          ))}
        </div>
        {/* Details Content */}
        <div
          className={`flex-1 p-12 bg-white rounded-2xl sm:p-0 sm:w-full sm:bg-[#F8F8F8] sm:absolute sm:top-0 sm:z-[1000] sm:h-screen sm:overflow-y-auto sm:rounded-none sm:overflow-x-hidden ${
            currentTab < 0 ? 'sm:hidden' : 'block'
          }`}
        >
          {/* Mobile Page Title */}
          <div className="hidden px-4 bg-white sm:flex">
            <div className="w-full grid h-30px grid-cols-3 mt-[25px] items-center">
              <div
                className="w-6 h-6 text-[#000]"
                onClick={() => changeCurrentTab(-1)}
              >
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
              <div className="text-[22px] leading-[30px] font-bold whitespace-nowrap justify-self-center">
                {
                  ['Data market', 'Account settings', 'Transaction history'][
                    currentTab
                  ]
                }
              </div>
            </div>
          </div>
          {
            [
              // Data market
              <div className="pb-[29px] sm:pb-0 sm:h-full" key={0}>
                <div className="text-[28px] leading-[33px] font-boldText font-bold sm:hidden">
                  Data market
                </div>
                {userBalance ? (
                  <div className="mt-12 sm:m-0 sm:pt-10 sm:bg-white sm:px-4 sm:pb-16">
                    <div className="flex items-center justify-between text-sm sm:text-base">
                      <div className="flex items-center sm:rounded-3xl sm:bg-[#F8F8F8] sm:py-4 sm:px-7 sm:text-[14px] sm:leading-[18px]">
                        <div className="pr-4 border-r-2 border-solid border-[#E0E0E0] sm:pr-4 sm:border-r cursor-pointer">
                          $CTIO NFT
                        </div>
                        {/* <div className="text-[#BDBDBD] ml-4 cursor-pointer">
                          $ETH
                        </div> */}
                      </div>
                      <div className="flex items-center text-[#D1D1D1] cursor-pointer">
                        <div className="text-sm font-numberText">24H</div>
                        <svg
                          className="w-3 h-3 ml-1"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-6 text-[32px] leading-9 font-numberText font-bold sm:mt-14 sm:text-[42px] sm:text-center flex flex-row items-center">
                      <div className="mr-2 w-[24px] h-[24px]">
                        <img className="w-full h-full" src={eth} alt="eth" />
                      </div>
                      <span>{market}</span>
                    </div>
                    <div className="mt-2 text-[#828282] text-[12px] leading-4 font-numberText sm:mt-[30px] sm:text-center sm:text-base">
                      {new Date().toLocaleString()}
                    </div>
                    <div className="h-[200px] mt-8 sm:mt-[52px] sm:h-[156px]">
                      {/* TODO: Charts */}
                      <TransactionsChart
                        title="Transaction"
                        grid={{}}
                        data={[
                          'receipt_gas_used',
                          'gas',
                          'receipt_cumulative_gas_used',
                        ]}
                        xAxis_data={{
                          type: 'category',
                          data: xData,
                        }}
                        series_data={[
                          {
                            name: 'gas',
                            type: 'line',
                            smooth: true,
                            data: seriesData?.gas,
                          },
                          {
                            name: 'receipt_gas_used',
                            type: 'line',
                            smooth: true,
                            data: seriesData?.receipt_gas_used,
                          },
                          {
                            name: 'receipt_cumulative_gas_used',
                            type: 'line',
                            smooth: true,
                            data: seriesData?.receipt_cumulative_gas_used,
                          },
                        ]}
                      />
                    </div>
                    <div className="grid grid-cols-4 mt-[5rem]  sm:grid-cols-2 sm:mt-8 sm:px-[10px] sm:py-8 sm:rounded-2xl sm:shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] sm:gap-x-8 sm:gap-y-[38px]">
                      {/* FIXME: some data need to fill*/}
                      {[
                        { title: 'Data market', value: `${market} ETH` },
                        {
                          title: 'Total Transactions',
                          value: totalTransaction,
                        },
                        { title: 'Total CTIO Backing', value: '53.9K ' },
                        { title: 'Total mint NFT', value: totalNFT },
                      ].map((x, i) => (
                        <div key={i}>
                          <div className="text-[#828282] text-sm">
                            <div className="flex items-center cursor-pointer group whitespace-nowrap">
                              {x.title}
                              <Tooltip message="CTIO Date market" />
                            </div>
                          </div>
                          <div className="font-numberText text-[22px] leading-[20px] mt-3">
                            {x.value}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="mt-12 sm:m-0 sm:pt-12 h-[380px] sm:bg-white flex flex-col items-center sm:h-full">
                    <img
                      src={noConnectWallet}
                      alt="noConnectWallet"
                      className="w-[243px] object-cover"
                    />
                    <div className="text-[#828282] mt-8">
                      No data yet, please connect your wallet first
                    </div>
                    <button
                      className="w-[119px] h-12 rounded-[60px] bg-[linear-gradient(270deg,#1E94FC_0%,#AED8FF_100%)] text-white text-sm mt-8 hover:shadow-[0_6px_18px_4px_#c0e2ff]"
                      onClick={handleConnectWallet}
                    >
                      Link wallet
                    </button>
                  </div>
                )}
              </div>,
              // Profile Details
              <div className="pb-[49px] sm:pb-0" key={1}>
                <div className="text-[28px] leading-[33px] font-boldText font-bold sm:hidden">
                  Profile Details
                </div>
                <div className="flex mt-12 sm:m-0">
                  <img
                    src={uploadPhoto ?? avatar}
                    alt="avatar"
                    className="h-[109px] w-[109px] rounded-full object-cover sm:hidden"
                  />
                  <div className="ml-[27px] flex flex-col justify-center w-full sm:m-0 pt-8 sm:pt-0">
                    <div className="h-[50px] text-[#1E94FC] flex sm:hidden">
                      <label
                        htmlFor="photo"
                        className="flex bg-[#F2F2F2] rounded-lg w-[214px] h-full justify-center items-center cursor-pointer m-0"
                      >
                        Upload Profile Photo
                        <input
                          onChange={handleUploadPhoto}
                          id="photo"
                          type="file"
                          className="hidden"
                        />
                      </label>
                      {/* <button
                        className="bg-white border-2 border-solid border-[#1E94FC] w-[110px] h-full rounded-lg ml-6"
                        onClick={() => {
                          reset()
                          setUploadPhoto(null)
                        }}
                      >
                        Remove
                      </button> */}
                    </div>
                    <div className="mt-5 text-[14px] leading-4 text-[#828282] sm:hidden">
                      * Image size should be at least 400px big, and less then
                      500kb. Allowed files .png and .jpg.
                    </div>
                    <form
                      className="mt-12 sm:mt-0"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {/* Mobile Profile Photo */}
                      <label
                        htmlFor="photo"
                        className="items-center justify-between hidden pb-8 sm:flex border-b border-[#F2F2F2] sm:pt-8 sm:px-4 sm:bg-white"
                      >
                        <div className="text-[18px] leading-[21px]">
                          Profile Photo
                        </div>
                        <input
                          onChange={handleUploadPhoto}
                          id="photo"
                          type="file"
                          className="hidden"
                        />
                        <div className="flex items-center justify-end">
                          <img
                            src={uploadPhoto ?? avatar}
                            alt="avatar"
                            className="object-cover w-10 h-10 rounded-full"
                          />
                          <svg
                            className="w-5 h-5 text-[#BDBDBD] ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </div>
                      </label>
                      {/* First Name & Last Name */}
                      <div className="flex sm:flex-col sm:px-4 sm:bg-white">
                        <div className="flex-1 sm:mt-[30px] sm:pb-8 sm:border-b sm:border-[#F2F2F2]">
                          <div className="sm:flex sm:justify-between">
                            <label
                              htmlFor="firstName"
                              className="text-[20px] leading-[23px] font-boldText font-bold sm:font-normal sm:font-regularText sm:text-[18px] sm:leading-[21px]"
                            >
                              First Name
                            </label>
                            <div className="relative sm:flex sm:items-center">
                              <input
                                {...register('firstName', {
                                  required: 'Please enter your FirstName.',
                                })}
                                type="text"
                                id="firstName"
                                placeholder="First Name"
                                className={`w-full h-[58px] border border-solid outline-none p-5 placeholder:text-[#BDBDBD] text-[#333] text-[18px] mt-2 rounded-lg sm:h-6 sm:leading-[21px] sm:p-0 sm:border-none sm:m-0 sm:text-right sm:rounded-none sm:text-[16px] ${
                                  errors.firstName
                                    ? 'border-red-500 '
                                    : 'border-[#E0E0E0]'
                                }`}
                              />
                              {watch('firstName') && (
                                <CleanValueIcon
                                  className="-translate-y-1 sm:-translate-y-1/2 sm:hidden"
                                  onClick={() => setValue('firstName', '')}
                                />
                              )}
                              <svg
                                className="w-5 h-5 text-[#BDBDBD] ml-2 hidden sm:block"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </div>
                          </div>
                          {errors.firstName && (
                            <ErrorMessage
                              className="sm:mt-0"
                              message={errors.firstName.message}
                            />
                          )}
                        </div>
                        <div className="ml-[28px] flex-1 sm:m-0 sm:mt-[30px] sm:pb-8 sm:border-b sm:border-[#F2F2F2]">
                          <div className="sm:flex sm:justify-between">
                            <label
                              htmlFor="lastName"
                              className="text-[20px] leading-[23px] font-boldText font-bold sm:font-normal sm:font-regularText sm:text-[18px] sm:leading-[21px]"
                            >
                              Last Name
                            </label>
                            <div className="relative sm:flex sm:items-center">
                              <input
                                {...register('lastName', {
                                  required: 'Please enter your LastName.',
                                })}
                                type="text"
                                id="lastName"
                                placeholder="Last Name"
                                className={`w-full h-[58px] border border-solid outline-none p-5 placeholder:text-[#BDBDBD] text-[#333] text-[18px] mt-2 rounded-lg sm:h-6 sm:leading-[21px] sm:p-0 sm:border-none sm:m-0 sm:text-right sm:rounded-none sm:text-[16px] ${
                                  errors.lastName
                                    ? 'border-red-500 '
                                    : 'border-[#E0E0E0]'
                                }`}
                              />
                              {watch('lastName') && (
                                <CleanValueIcon
                                  className="-translate-y-1 sm:-translate-y-1/2 sm:hidden"
                                  onClick={() => setValue('lastName', '')}
                                />
                              )}
                              <svg
                                className="w-5 h-5 text-[#BDBDBD] ml-2 hidden sm:block"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </div>
                          </div>
                          {errors.lastName && (
                            <ErrorMessage
                              className="sm:mt-0"
                              message={errors.lastName.message}
                            />
                          )}
                        </div>
                      </div>
                      {/* Email */}
                      <div className="w-full mt-12 sm:mt-0 sm:pt-[30px] sm:pb-8 sm:border-b sm:border-[#F2F2F2] sm:px-4 sm:bg-white">
                        <div className="sm:flex sm:justify-between">
                          <label
                            htmlFor="email"
                            className="text-[20px] leading-[23px] font-boldText font-bold sm:font-normal sm:font-regularText sm:text-[18px] sm:leading-[21px]"
                          >
                            Email
                          </label>
                          <div className="relative sm:flex sm:items-center">
                            <input
                              {...register('email', {
                                required: 'Please enter your Email.',
                              })}
                              type="email"
                              id="email"
                              placeholder="Email"
                              className={`w-full h-[58px] border border-solid outline-none p-5 placeholder:text-[#BDBDBD] text-[#333] text-[18px] mt-2 rounded-lg sm:bg-white sm:h-6 sm:leading-[21px] sm:p-0 sm:border-none sm:m-0 sm:text-right sm:rounded-none sm:text-[16px] ${
                                errors.email
                                  ? 'border-red-500 '
                                  : 'border-[#E0E0E0]'
                              }`}
                            />
                            {/* {watch('email') && (
                              <CleanValueIcon
                                className="-translate-y-1 sm:-translate-y-1/2 sm:hidden"
                                onClick={() => setValue('email', '')}
                              />
                            )} */}
                            <svg
                              className="w-5 h-5 text-[#BDBDBD] ml-2 hidden sm:block"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </div>
                        </div>
                        {errors.email && (
                          <ErrorMessage
                            className="sm:mt-0"
                            message={errors.email.message}
                          />
                        )}
                      </div>
                      {/* About */}
                      <div className="w-full mt-12 sm:pt-[30px] sm:mt-4 sm:pb-8 sm:border-b sm:border-[#F2F2F2] sm:px-4 sm:bg-white">
                        <label
                          htmlFor="about"
                          className="text-[20px] leading-[23px] font-boldText font-bold sm:font-normal sm:font-regularText sm:text-[18px] sm:leading-[21px]"
                        >
                          About
                        </label>
                        <div className="relative">
                          <textarea
                            {...register('about')}
                            id="about"
                            placeholder="You can introduce yourself in simple words."
                            className="w-full border border-solid border-[#E0E0E0] outline-none p-5 placeholder:text-[#E0E0E0] text-[#333] text-[18px] mt-2 rounded-lg h-[114px] resize-none sm:border-0 sm:rounded-none sm:p-0 sm:mt-6 sm:h-14 sm:text-[16px]"
                          />
                          {watch('about') && (
                            <CleanValueIcon
                              className="top-[80%] sm:hidden"
                              onClick={() => setValue('about', '')}
                            />
                          )}
                        </div>
                      </div>
                      {/* Button */}
                      <div className="flex mt-12 sm:flex-col sm:mt-0 sm:pt-[67px] sm:px-4 sm:bg-white sm:pb-[52px]">
                        <button className="rounded-[60px] w-[110px] h-[50px] bg-[linear-gradient(270deg,#1E88E5_0%,#64B5F6_100%)] text-white sm:w-full sm:rounded-[30px]">
                          Save
                        </button>
                        <div className="rounded-[60px] w-[110px] h-[50px] bg-[#F2F2F2] text-[#828282] ml-4 flex justify-center items-center sm:w-full sm:rounded-[30px] sm:ml-0 sm:mt-4">
                          Cancle
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>,
              // Transaction history
              <div className="pb-[31px] sm:pb-0" key={1}>
                <div className="flex items-center justify-between sm:hidden">
                  <div className="text-[28px] leading-[33px] font-boldText font-bold">
                    Transaction history
                  </div>
                  <svg
                    className="w-6 h-6 text-[#4F4F4F] cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
                <div className="h-full mt-12 sm:m-0 sm:pt-8 sm:px-4 sm:bg-white">
                  {listLoading ? (
                    <SpinLoading className="min-h-[650px] mt-10" />
                  ) : (
                    <div className="text-center sm:text-left">
                      <div className="grid grid-cols-5 items-center h-[60px] border-y border-solid border-[#E0E0E0] sm:hidden">
                        {[
                          'Name',
                          'Comment',
                          'Deadline',
                          'Amount',
                          'Transaction',
                        ].map((x, i) => (
                          <div
                            key={i}
                            className="text-[#BDBDBD] first:justify-self-start"
                          >
                            {x}
                          </div>
                        ))}
                      </div>
                      {transactionHistoryList.map((x, i) => {
                        const randomIndex = mock('@integer(0,4)')
                        return (
                          <>
                            {/* PC Table Content */}
                            <div
                              key={i}
                              className="grid grid-cols-5 items-center h-[92px] text-[#4F4F4F] text-[14px] leading-[36px] border-b border-solid border-[#E0E0E0] sm:hidden"
                            >
                              <div className="flex items-center">
                                <div
                                  className={`w-[42px] h-[42px] rounded-2xl text-[16px] leading-[20px] flex justify-center items-center`}
                                  style={{
                                    color: randomColor[randomIndex].textColor,
                                    backgroundColor:
                                      randomColor[randomIndex].bgColor,
                                  }}
                                >
                                  {x.name.slice(0, 2)}
                                </div>
                                <div className="ml-3">{x.name}</div>
                              </div>
                              <div>{x.comment}</div>
                              <div>{x.deadline}</div>
                              <div
                                className={`font-numberText ${
                                  x.amount > 0
                                    ? 'text-[#25BDA2]'
                                    : 'text-[#EB5757]'
                                }`}
                              >
                                ${x.amount}
                              </div>
                              <div
                                className={`flex items-center justify-center ${
                                  x.amount > 0
                                    ? 'text-[#25BDA2]'
                                    : 'text-[#EB5757]'
                                }`}
                              >
                                <svg
                                  className="w-8 h-8 mr-2"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  {x.amount > 0 ? (
                                    <path
                                      fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                      clipRule="evenodd"
                                    />
                                  ) : (
                                    <path
                                      fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
                                      clipRule="evenodd"
                                    />
                                  )}
                                </svg>
                                {x.transaction}
                              </div>
                            </div>
                            {/* Mobile List Content */}
                            <div
                              key={i}
                              className="hidden sm:flex h-[108px] bg-[#F8F8F8] mb-6 px-4 rounded-2xl justify-between items-center"
                            >
                              <div className="flex items-center">
                                <div
                                  className={`w-[42px] h-[42px] rounded-2xl text-[16px] leading-[20px] flex justify-center items-center`}
                                  style={{
                                    color: randomColor[randomIndex].textColor,
                                    backgroundColor:
                                      randomColor[randomIndex].bgColor,
                                  }}
                                >
                                  {x.name.slice(0, 2)}
                                </div>
                                <div className="pl-3">
                                  <div className="text-[18px] leading-7 font-boldText font-bold">
                                    {x.name}
                                  </div>
                                  <div className="text-[#4F4F4F] text-[14px] leading-[16px] font-numberText whitespace-nowrap">
                                    {x.deadline}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`font-numberText leading-9 font-medium ${
                                  x.amount > 0
                                    ? 'text-[#25BDA2]'
                                    : 'text-[#EB5757]'
                                }`}
                              >
                                {x.amount > 0 ? '+' : '-'}$
                                {String(x.amount).slice(1)}
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  )}
                  <Pagination
                    onChange={onPageChange}
                    classname="mt-10 sm:mt-10 sm:pb-16"
                  />
                </div>
              </div>,
            ][currentTab]
          }
        </div>
      </div>
    </div>
  )
}

export default User
