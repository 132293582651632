import React from 'react'
import ReactECharts from 'echarts-for-react'
type Props = {
  title: string
  data: string[]
  grid?: any
  xAxis_data: any
  series_data: any
}

function TransactionsChart({
  title,
  data,
  grid,
  xAxis_data,
  series_data,
}: Props) {
  const option = {
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data,
    },
    grid: grid,
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: xAxis_data,
    yAxis: {
      type: 'value',
    },
    series: series_data,
  }
  return <ReactECharts option={option} notMerge={true} />
}

export default TransactionsChart
